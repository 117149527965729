<template>
    <div class="page">
        <table
            id="table"
            class="pure_table"
            border="1"
            v-for="(tableDetail, index) in tableDetails"
            :key="index"
        >
            <tr>
                <th
                    class="title"
                    colspan="20"
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_header')"
                >
                    {{ salary_header }}工资单（单位：元）
                </th>
            </tr>
            <tr class="head">
                <th rowspan="2">姓名</th>
                <th rowspan="2">岗位</th>
                <th rowspan="2">出勤天数</th>
                <th rowspan="2">加班时数</th>
                <th colspan="6">应发</th>
                <th colspan="6">应扣</th>
                <th
                    rowspan="2"
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'tax')"
                >
                    税点
                </th>
                <th
                    rowspan="2"
                    :class="{ editable: isEdit }"
                    @blur="
                        refresh(
                            $event,
                            'salary_tpl_text',
                            'deduct_social_security'
                        )
                    "
                >
                    {{
                        tableHeader.deduct_social_security ||
                        "单位须承担社保费部分"
                    }}
                </th>
                <th
                    rowspan="2"
                    :class="{ editable: isEdit }"
                    @blur="
                        refresh(
                            $event,
                            'salary_tpl_text',
                            'deduct_accumulation_fund'
                        )
                    "
                >
                    {{
                        tableHeader.deduct_accumulation_fund ||
                        "单位须承担住房公积金部分"
                    }}
                </th>
                <th rowspan="2">实收</th>
            </tr>
            <tr class="head">
                <th>基本工资</th>
                <th>加班工资</th>
                <th
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'grant_1')"
                >
                    {{ tableHeader.grant_1 || "绩效奖金" }}
                </th>
                <th
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'grant_2')"
                >
                    {{ tableHeader.grant_2 || "高温津贴" }}
                </th>
                <th
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'grant_other')"
                >
                    {{ tableHeader.grant_other || "其他" }}
                </th>
                <th>小计</th>
                <th
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'deduct_1')"
                >
                    {{ tableHeader.deduct_1 || "社保" }}
                </th>
                <th
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'deduct_2')"
                >
                    {{ tableHeader.deduct_2 || "公积金" }}
                </th>
                <th
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'deduct_3')"
                >
                    {{ tableHeader.deduct_3 || "水电" }}
                </th>
                <th
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'deduct_4')"
                >
                    {{ tableHeader.deduct_4 || "借支" }}
                </th>
                <th
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'deduct_other')"
                >
                    {{ tableHeader.deduct_other || "其他" }}
                </th>
                <th>小计</th>
            </tr>
            <tr class="tr flat">
                <!-- :class="{ editable: isEdit }"
                    
                    @input="handelInput($event, 'real_name')" -->
                <td>
                    {{ tableDetail.staff ? tableDetail.staff.real_name : "" }}
                </td>
                <td>
                    {{
                        tableDetail.staff
                            ? tableDetail.staff.department_data.station_name
                            : ""
                    }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'punch_day')"
                    v-if="tableDetail.punch_day < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.punch_day }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'punch_day')"
                    v-else
                >
                    {{ tableDetail.punch_day }}
                </td>

                <td
                    v-if="tableDetail.overtime < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.overtime }}
                </td>
                <td v-else>{{ tableDetail.overtime }}</td>
                <td
                    v-if="tableDetail.base_pay < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.base_pay }}
                </td>
                <td v-else>{{ tableDetail.base_pay }}</td>
                <td
                    v-if="tableDetail.overtime_pay < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.overtime_pay }}
                </td>
                <td v-else>{{ tableDetail.overtime_pay }}</td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'grant_1')"
                    v-if="tableDetail.grant_1 < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.grant_1 }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'grant_1')"
                    v-else
                >
                    {{ tableDetail.grant_1 }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'grant_2')"
                    v-if="tableDetail.grant_2 < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.grant_2 }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'grant_2')"
                    v-else
                >
                    {{ tableDetail.grant_2 }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'grant_other')"
                    v-if="tableDetail.grant_other < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.grant_other }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'grant_other')"
                    v-else
                >
                    {{ tableDetail.grant_other }}
                </td>
                <td
                    v-if="tableDetail.grant_subtotal < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.grant_subtotal }}
                </td>
                <td v-else>{{ tableDetail.grant_subtotal }}</td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'deduct_1')"
                    v-if="tableDetail.deduct_1 < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.deduct_1 }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'deduct_1')"
                    v-else
                >
                    {{ tableDetail.deduct_1 }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'deduct_2')"
                    v-if="tableDetail.deduct_2 < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.deduct_2 }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'deduct_2')"
                    v-else
                >
                    {{ tableDetail.deduct_2 }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'deduct_3')"
                    v-if="tableDetail.deduct_3 < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.deduct_3 }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'deduct_3')"
                    v-else
                >
                    {{ tableDetail.deduct_3 }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'deduct_4')"
                    v-if="tableDetail.deduct_4 < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.deduct_4 }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'deduct_4')"
                    v-else
                >
                    {{ tableDetail.deduct_4 }}
                </td>

                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'deduct_other')"
                    v-if="tableDetail.deduct_other < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.deduct_other }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'deduct_other')"
                    v-else
                >
                    {{ tableDetail.deduct_other }}
                </td>
                <td
                    v-if="tableDetail.deduct_subtotal < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.deduct_subtotal }}
                </td>
                <td v-else>{{ tableDetail.deduct_subtotal }}</td>

                <td v-if="tableDetail.tax < 0" style="background-color: red;">
                    {{ tableDetail.tax }}
                </td>
                <td v-else>{{ tableDetail.tax }}</td>
                <td
                    v-if="tableDetail.deduct_social_security < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.deduct_social_security }}
                </td>
                <td v-else>{{ tableDetail.deduct_social_security }}</td>
                <td
                    v-if="tableDetail.deduct_accumulation_fund < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.deduct_accumulation_fund }}
                </td>
                <td v-else>{{ tableDetail.deduct_accumulation_fund }}</td>
                <td
                    v-if="tableDetail.actual_salary < 0"
                    style="background-color: red;"
                >
                    {{ tableDetail.actual_salary }}
                </td>
                <td v-else>{{ tableDetail.actual_salary }}</td>
            </tr>
            <tr>
                <td class="sign" colspan="20">员工签收：</td>
            </tr>
        </table>
    </div>
</template>

<script>
import printTable from "print-js";
import { mapActions } from "vuex";

export default {
    data() {
        return {
            tableHeader: {},
            tableDetails: [],
            isEdit: true,
        };
    },
    props: ["id", "date"],
    created() {
        sessionStorage.setItem("SalaryAuditSearchDate", this.date);
        this.getSalaryAuditHeader()
            .then((res) => {
                console.log("头部", res);
                this.tableHeader = res.data.data.detail;
            })
            .catch((e) => {
                console.error(e);
            });

        this.id.forEach((id) => {
            this.getSalaryDetail({
                id: id,
            })
                .then((res) => {
                    console.log(res);
                    this.salary_header = res.data.data.salary_header;
                    this.tableDetails.push(
                        JSON.parse(JSON.stringify(res.data.data.detail))
                    );
                })
                .catch((e) => {
                    console.error(e);
                });
        });
    },
    mounted() {
        // this.print();
    },
    methods: {
        ...mapActions("pay", ["getSalaryAuditHeader", "getSalaryDetail"]),

        print() {
            printTable({
                printable: "table",
                type: "html",
                maxWidth: "100%",
                targetStyles: ["*"],
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import url(./css/table.css);

.btns {
    display: flex;
    margin-top: 25px;

    > div:nth-child(1) {
        margin-right: 10px;
    }
}
</style>
